var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container my-2"},[(_vm.loading)?_c('div',{staticClass:"loading my-5 w-100"},[_c('video',{attrs:{"autoplay":"","loop":"","muted":"","playsinline":"","width":"100vw"},domProps:{"muted":true}},[_c('source',{attrs:{"src":require("../../assets/easyload-xl.webm"),"type":"video/webm"}})])]):_vm._e(),(_vm.retEntry && _vm.reservationCompleted)?_c('div',{staticClass:"position-relative",class:{ 'd-none': !_vm.retEntry }},[(_vm.confrimLoading)?_c('div',{staticClass:"confirm-loading w-100"},[_c('video',{attrs:{"autoplay":"","loop":"","muted":"","playsinline":"","width":"100vw"},domProps:{"muted":true}},[_c('source',{attrs:{"src":require("../../assets/easyload-xl.webm"),"type":"video/webm"}})])]):_vm._e(),_c('transition',{attrs:{"name":"slide"}},[(_vm.retEntry && !_vm.anyErrors)?_c('div',{staticClass:"form-header--riepilogo-riepilogo lg"},[_c('recap-reservation'),_c('accordion',{attrs:{"items":_vm.retEntry,"keyName":'codice_prenotazione'},scopedSlots:_vm._u([(_vm.retEntry.length > 1)?{key:"sr-title",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.$t("form.n_prenotazione", { n: index + 1 }))+" ")]}}:null,{key:"title",fn:function(ref){
var item = ref.item;
var classes = ref.classes;
var index = ref.index;
var isActive = ref.isActive;
return [_c('recap-title',{attrs:{"index":index,"repeat":item,"showReservationNumber":_vm.retEntry.length > 1,"is-active":isActive}})]}},{key:"body",fn:function(ref){
var index = ref.index;
return [_c('recap-body',{attrs:{"index":index}})]}}],null,true)}),_c('div',{staticClass:"row position-sticky bottom-0 bg-white px-1"},[_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t("form.riepilogo.auto-delete", { minuti: _vm.secondsToString(_vm.timer, true) }))+" ")])]),_c('e-button',{ref:"conferma",staticClass:"btn btn-lg btn-primary my-2",class:{
                            disabled: _vm.disabled && _vm.reservationCompleted
                        },style:({
                            backgroundColor:
                                _vm.$store.state.Ente.colors &&
                                _vm.$route.name != 'Home'
                                    ? _vm.$store.state.Ente.colors.sfondo[0]
                                    : ''
                        }),on:{"click":_vm.conferma,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.conferma($event)}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.confirm"))+" ")])],1)],1):_vm._e()])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }