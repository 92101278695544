<template>
    <div class="container my-2">
        <div v-if="loading" class="loading my-5 w-100">
            <video autoplay loop muted playsinline width="100vw">
                <source src="../../assets/easyload-xl.webm" type="video/webm" />
            </video>
        </div>
        <div
            class="position-relative"
            :class="{ 'd-none': !retEntry }"
            v-if="retEntry && reservationCompleted"
        >
            <div v-if="confrimLoading" class="confirm-loading w-100">
                <video autoplay loop muted playsinline width="100vw">
                    <source
                        src="../../assets/easyload-xl.webm"
                        type="video/webm"
                    />
                </video>
            </div>
            <transition name="slide">
                <div
                    class="form-header--riepilogo-riepilogo lg"
                    v-if="retEntry && !anyErrors"
                >
                    <recap-reservation />

                    <accordion
                        :items="retEntry"
                        :keyName="'codice_prenotazione'"
                    >
                        <template v-slot:sr-title="{ item, index }" v-if="retEntry.length > 1">
                            {{ $t("form.n_prenotazione", { n: index + 1 }) }}
                        </template>
                        <template v-slot:title="{ item, classes, index, isActive }">
                            <recap-title :index="index" :repeat="item" :showReservationNumber="retEntry.length > 1" :is-active="isActive"/>
                        </template>
                        <template v-slot:body="{ index }">
                            <recap-body :index="index" />
                        </template>
                    </accordion>

                    <div class="row position-sticky bottom-0 bg-white px-1">
                        <div class="text-center">
                            <p class="text-danger">
                                {{
                                    $t("form.riepilogo.auto-delete", {
                                        minuti: secondsToString(timer, true)
                                    })
                                }}
                            </p>
                        </div>

                        <e-button
                            class="btn btn-lg btn-primary my-2"
                            @click="conferma"
                            @keyup.enter="conferma"
                            ref="conferma"
                            :class="{
                                disabled: disabled && reservationCompleted
                            }"
                            :style="{
                                backgroundColor:
                                    $store.state.Ente.colors &&
                                    $route.name != 'Home'
                                        ? $store.state.Ente.colors.sfondo[0]
                                        : ''
                            }"
                        >
                            {{ $t("buttons.confirm") }}
                        </e-button>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex";

import { book } from "../../mixins/book";
import { recapMixin } from "../Riepilogo/recap_mixin.js";

import RecapTitle from "../Riepilogo/recap_title.vue";
import RecapBody from "../Riepilogo/recap_body.vue";
import RecapReservation from "../Riepilogo/recap_reservation.vue";
//@group Form
export default {
    name: "Riepilogo",
    mixins: [recapMixin, book],
    components: {
        RecapTitle,
        RecapBody,
        RecapReservation
    },
    mounted() {
        this.executeRecapthca();
    },
    watch: {
        timer: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.timer--;
                    }, 1000);
                }
                if (value == 0) {
                    this.setError({
                        home: "server.errors.time_out"
                    });
                    this.fullRollBack();
                    this.disabled = true;
                    return;
                }
            },
            immediate: true // This ensures the watcher is triggered upon creation
        }
    },
        data() {
        return {
            disabled: false,
            confrimLoading: false,
            timer: 30 * 60
        };
    },
    methods: {
        ...mapMutations({
            setRetEntry: "Entry/SET_RETENTRY",
            resetReturnEntry: "Entry/RESET_RETURN_ENTRY",
            clearErrors: "Utils/CLEAR_ERRORS",
            setQrCode: "Entry/SET_QR_CODE"
        }),

        /**
         * @vuese
         * Esegue funzione di recaptcha se abilitata, poi effettua la prenotazione
         * viene chiamata nel mounted()
         */
        executeRecapthca() {
            if (this.$parent.$refs.recaptcha) {
                this.$parent.$refs.recaptcha.execute();
            } else {
                this.book();
            }
        },
        /**
         * @vuese
         * Chiama api per confermare prenotazione poi naviga verso Confirmed
         */
        async conferma() {
            if (this.retEntry) {
                this.confrimLoading = true;
                await this.retEntry.forEach(async e => {
                    await this.$http.post(`/api/entry/confirm/${e.entry}`);
                });
                this.confrimLoading = false;
                this.$router.push("Confirmed");
            }
        }
    }
};
</script>
<style>
.loading {
    height: 100%;
}

.confirm-loading {
    position: absolute;
    background-color: rgba(209, 209, 209, 0.322);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-header-riepilogo-riepilogo--box {
    border: 1px solid rgb(211, 211, 211);
    padding: 0.5rem 1rem;
    /* border-radius: 10px; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 20%;
    background-color: rgba(var(--custom-color-rgb-1500), 0.2);
    border-radius: 20px;
}
.header-risorsa {
    background-color: rgba(var(--custom-color-rgb-1500), 0.2);
}

ul {
    padding: 0.3rem;
}

.form-header--riepilogo-riepilogo.lg {
    background-color: white;
    padding: 0.9rem 1.5rem;
    display: flex;
    flex-direction: column;
}

.form-header--riepilogo-riepilogo--luogo.lg,
.form-header--riepilogo-riepilogo--datiutente {
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
}

.form-header--riepilogo-riepilogo--datiutente span:not(.dato) {
    font-size: 0.9rem;
    font-weight: bold;
    word-break: break-all;
}

.dato {
    word-break: break-all;
    display: inline-block;
}

.form-header--riepilogo-riepilogo--datiutente li {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    margin: 0.3rem 0;
    padding: 0.4rem 0;
    border-bottom: 1px solid rgb(211, 211, 211);
}

.form-header--riepilogo-riepilogo--luogo.lg li {
    margin-bottom: 10px;
    display: flex;
}

.form-header--riepilogo-riepilogo--luogo.lg li i {
    margin-right: 20px;
    font-size: 1.2rem;
}

.form-header--riepilogo-riepilogo--dati.lg {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    font-size: 1rem;
    margin-top: 1rem auto;
    flex-wrap: wrap;
}

.form-header--riepilogo-riepilogo--dati.lg li {
    margin-right: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-header--riepilogo-riepilogo--dati.lg li i {
    margin-right: 10px;
}

@media screen and (max-width: 600px) {
    .form-header--riepilogo-riepilogo.lg {
        width: 100%;
        padding: 0;
    }

    .form-header--riepilogo {
        padding: 0.8rem 0.3rem;
    }

    .form-header-riepilogo-riepilogo--box {
        padding-left: 1rem;
    }
}
</style>
