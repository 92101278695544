<template>
    <span
        class="header-risorsa d-flex justify-content-between align-content-center p-2 mt-3 border rounded position-relative  "
    >
        <span v-if="showReservationNumber">
            {{
                $t("form.n_prenotazione", {
                    n: index + 1
                })
            }}
        </span>
        <span v-else>
            <span class="no-print text-muted" style="cursor: pointer">
                {{ $t("buttons.open_accordion") }}
            </span>
        </span>
        <span
            v-if="showReservationNumber"
            class="no-print text-muted text-small"
        >
            <small>
                <span class="d-none d-md-inline-block">
                    {{ $t("buttons.open_accordion") }}
                </span>
                <span class="ml-1">
                    <i class="fas fa-chevron-up" v-if="isActive"></i>
                    <i class="fas fa-chevron-down" v-else></i>
                </span>
            </small>
        </span>
        <span v-if="!publicPosition && !entry.servizio.servizio_breve">
            <i class="fa-solid fa-house-laptop"></i>
            <span style="font-weight : bold;">
                {{ $t("risorsa") }}
            </span>
            <span>{{ repeat.risorsa.resource_name }}</span>
        </span>
    </span>
</template>

<script>
import { recapMixin } from "./recap_mixin.js";
export default {
    name: "RecapTitle",
    mixins: [recapMixin],
    props: {
        repeat: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        showReservationNumber: {
            type: Boolean,
            default: false
        },
        isActive: {
            type: Boolean,
            default: false
        }
    }
};
</script>
