<template>
    <div>
        <ul class="form-header--riepilogo-riepilogo--datiutente lg">
            <li v-for="(dati, x) in dati" :key="x">
                <template
                    v-if="
                        ['history_select', 'select'].includes(
                            form.filter(e => e.codice == x)[0].tipo
                        )
                    "
                >
                    <span v-tooltip="formLabel(x)">
                        <span v-html="formLabel(x)"></span>:
                    </span>
                    <span class="dato">
                        <template
                            v-if="
                                form.filter(e => e.codice == x)[0].tipo ==
                                    'select'
                            "
                        >
                            {{
                                form.filter(e => e.codice == x)[0].valori[dati]
                            }}
                        </template>
                        <template v-else
                            >{{
                                getHistorySelectValue(
                                    form.filter(e => e.codice == x)[0],
                                    dati
                                )
                            }}
                        </template>
                    </span>
                </template>
                <template
                    v-else-if="
                        ['checkbox'].includes(
                            form.filter(e => e.codice == x)[0].tipo
                        )
                    "
                >
                    <span v-tooltip="formLabel(x)">
                        <span v-html="formLabel(x)"></span>:
                    </span>

                    <span class="dato" v-if="dati">{{$t('si')}}</span>

                    <span class="dato" v-else>{{$t('no')}}</span>
                </template>

                <template v-else>
                    <span v-tooltip="formLabel(x)">
                        <span v-html="formLabel(x)"></span>:
                    </span>

                    <span class="dato"> {{ dati }}</span>
                </template>
            </li>
        </ul>
    </div>
</template>

<script>
import { recapMixin } from "./recap_mixin.js";
export default {
    name: "RecapBody",
    mixins: [recapMixin],
    props: {
        index: {
            type: Number,
            required: true
        }
    },
    computed: {
        dati() {
            return Object.keys(this.datiUtente[this.index][0])
                .filter(key => key != "gdpr")
                .reduce((obj, key) => {
                    obj[key] = this.datiUtente[this.index][0][key];
                    return obj;
                }, {});
        }
    },
    methods: {
        /**
         * @vuese
         * @arg indice :number
         * cerca label del campo ritrona la traduzione se disponibile o l'originale se non disponibile
         */

        formLabel(i) {
            const codice = this.form.filter(e => e.codice == i)[0].codice;
            const label = this.form.filter(e => e.codice == i)[0].label;
            console.log(
                i == 1721984338,
                this.form.filter(e => e.codice == i)[0].label
            );

            return this.$t(`form.campi.${codice}`) == `form.campi.${codice}`
                ? label
                : this.$t(`form.campi.${codice}`);
        },
        getHistorySelectValue(form, valore) {
            let presente = form.valori.filter(e => e.id == valore);
            if (presente.length > 0) return presente[0].text;
            return valore;
        }
    }
};
</script>
