<template>
    <div class="form-header-riepilogo-riepilogo--box">
        <ul class="form-header--riepilogo-riepilogo--luogo lg">
            <li v-if="entry.servizio.type" class="mr-2 servizio">
                <i class="fas fa-concierge-bell mt-1"></i>
                <span v-html="entry.servizio.type"> </span>
            </li>

            <li v-if="entry.area.area_name" class="mr-2 area">
                <i class="far fa-building mt-1"></i>
                <span v-html="entry.area.area_name"></span>
            </li>

            <li v-if="entry.area.address" class="indirizzo">
                <i class="fas fa-map-marker-alt mt-1"></i>
                <span v-html="entry.area.address"></span>
            </li>
        </ul>
        <ul class="form-header--riepilogo-riepilogo--dati lg">
            <li>
                <i class="far fa-calendar-alt"></i>
                {{
                    new Date(retEntry[0].start_time * 1000).toLocaleDateString(
                        lang,
                        {
                            weekday: "short",
                            year: "numeric",
                            month: "numeric",
                            day: "numeric"
                        }
                    )
                }}
            </li>
            <li>
                <i class="far fa-clock"></i>
                {{
                    entry.data
                        .toLocaleTimeString(lang)
                        .replace(/(.*)\D\d+/, "$1")
                }}

                <template v-if="!hideEndTimeFromSoftware"> - {{ endTime }}</template>
            </li>
        </ul>
    </div>
</template>

<script>
import { showsEndTime } from "../../mixins/showsEndTime.js";
import { recapMixin } from "./recap_mixin.js";
export default {
    name: "RecapReservation",
    mixins: [recapMixin, showsEndTime ]
};
</script>
