import { mapState } from "vuex";
import { publicPosition } from "../../mixins/publicPosition.js";

export const recapMixin = {
    mixins: [publicPosition],
    computed: {
        ...mapState({
            entry: state => state.Entry,
            form: state => state.Utils.form,
            datiUtente: state => state.Entry.datiUtente,
            errors: state => state.Utils.errors,
            retEntry: state => state.Entry.retEntry,
            number_of_reservations: state =>
                Number(state.Entry.number_of_reservations)
        })
    }
};
