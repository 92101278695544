var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ul',{staticClass:"form-header--riepilogo-riepilogo--datiutente lg"},_vm._l((_vm.dati),function(dati,x){return _c('li',{key:x},[(
                    ['history_select', 'select'].includes(
                        _vm.form.filter(function (e) { return e.codice == x; })[0].tipo
                    )
                )?[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.formLabel(x)),expression:"formLabel(x)"}]},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.formLabel(x))}}),_vm._v(": ")]),_c('span',{staticClass:"dato"},[(
                            _vm.form.filter(function (e) { return e.codice == x; })[0].tipo ==
                                'select'
                        )?[_vm._v(" "+_vm._s(_vm.form.filter(function (e) { return e.codice == x; })[0].valori[dati])+" ")]:[_vm._v(_vm._s(_vm.getHistorySelectValue( _vm.form.filter(function (e) { return e.codice == x; })[0], dati ))+" ")]],2)]:(
                    ['checkbox'].includes(
                        _vm.form.filter(function (e) { return e.codice == x; })[0].tipo
                    )
                )?[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.formLabel(x)),expression:"formLabel(x)"}]},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.formLabel(x))}}),_vm._v(": ")]),(dati)?_c('span',{staticClass:"dato"},[_vm._v(_vm._s(_vm.$t('si')))]):_c('span',{staticClass:"dato"},[_vm._v(_vm._s(_vm.$t('no')))])]:[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.formLabel(x)),expression:"formLabel(x)"}]},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.formLabel(x))}}),_vm._v(": ")]),_c('span',{staticClass:"dato"},[_vm._v(" "+_vm._s(dati))])]],2)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }